import React from "react";
import Button from "../Button";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";
import { useContactModal } from "../Modal/contactModalContext";
function LetsTalkCTA({
  title = "Are you a IT Leader?",
  btnText = "LET'S TALK",
  onClick,
  titleClass = "",
  wrapClass = "",
}) {
  const { setOpenModal } = useContactModal();
  return (
    <div
      className={`lets-talk-wrap body-container common-bg-light ${wrapClass}`}
    >
      <div className="lets-talk-container ">
        <RevealAnimation
          component={"h2"}
          className={`title-header ${titleClass}`}
        >
          {title}
        </RevealAnimation>
        <Button
          btnWrapClassName="w-full"
          icon={
            <img
              className="effect-btn-svgicon"
              src={RightArrowWhite}
              alt="Lets Talk"
            />
          }
          customClassName="info-view-resize-btn"
          color="primary"
          variant="contained"
          label={btnText}
          tooltip="no"
          onClick={() => (onClick ? onClick() : setOpenModal(true))}
        />
      </div>
    </div>
  );
}

export default LetsTalkCTA;
