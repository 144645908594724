import React, { useRef } from "react";
import Layout from "../../components/layouts/layout";
import LPFooter from "../../components/LPFooter";
import RevealAnimation from "../../components/RevealAnimation";
import TrustedPartner from "../../components/trustedPartner";
import heroSection from "../../assets/Images/landing-page/banner.svg";
import LandingList from "../../components/landingList";
import IconCardList from "../../templates/IconCardList";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import Button from "../../components/Button";

import DataIcon from "../../assets/Images/landing-page/features/data.png";
import CommunicationIcon from "../../assets/Images/landing-page/features/communication.png";
import DeadlineImg from "../../assets/Images/landing-page/features/deadlines.png";
import ProgressImg from "../../assets/Images/landing-page/features/progress.png";
import ReduceCostImg from "../../assets/Images/landing-page/features/reduce-cost.png";
import TestingImg from "../../assets/Images/landing-page/features/testing.png";

import AgileImg from "../../assets/Images/landing-page/agile.png";
import InitialImg from "../../assets/Images/landing-page/initial.png";
import OnboardingImg from "../../assets/Images/landing-page/onboarding.png";
import PerformanceImg from "../../assets/Images/landing-page/performance.png";
import QualityImg from "../../assets/Images/landing-page/testing.png";
import TalentImg from "../../assets/Images/landing-page/talent.png";
import TeamImg from "../../assets/Images/landing-page/team.png";
import RameshImg from "../../assets/Images/landing-page/ramesh.png";
import HarryImg from "../../assets/Images/landing-page/harry.png";
import HarishImg from "../../assets/Images/landing-page/harish.png";

import TitleImgSection from "../../components/TitleListImgSection/cardItem";
// import { navigate } from "gatsby";
import DigiryteLogo from "../../assets/Images/Homepage/Digiryte-logo.svg";
import { InlineWidget } from "react-calendly";
import LetsTalkCTA from "../../components/LetsTalkCTA";
import AutoSlideImage from "../../components/AutoSlideImage";
import ClientLogos from "../../assets/Images/landing-page/client.jpg";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.26825" width="24" height="24" rx="12" fill="#E89F38" />
      <path
        d="M18 5.86823L17.8364 5.75312L17.8363 5.75327L17.8359 5.75391L17.834 5.75655L17.8265 5.76719L17.7965 5.80952C17.77 5.84695 17.7303 5.9026 17.6783 5.97528C17.5743 6.12065 17.4205 6.33415 17.2221 6.60627C16.8253 7.15052 16.2497 7.92922 15.5354 8.86637C14.1064 10.7409 12.1229 13.2483 9.90367 15.7811C9.37776 16.381 8.90051 16.6 8.49311 16.6261C8.08233 16.6524 7.70105 16.4853 7.36422 16.2294C7.02742 15.9736 6.75049 15.6402 6.55592 15.3658C6.45924 15.2295 6.38428 15.1097 6.33373 15.0244C6.30847 14.9818 6.28938 14.948 6.27678 14.9251L6.26281 14.8994L6.25956 14.8932L6.25889 14.8919L6.25886 14.8919C6.25884 14.8918 6.25882 14.8918 6.25881 14.8918L6.2588 14.8918C6.2192 14.8152 6.13499 14.7726 6.04984 14.7861C5.96465 14.7996 5.89771 14.8662 5.88377 14.9513C5.69619 16.0971 5.8337 16.9783 6.19672 17.6173C6.56149 18.2594 7.14102 18.634 7.78759 18.7867C9.06416 19.0883 10.625 18.5323 11.4303 17.46L11.4304 17.46C12.5341 15.9903 14.2229 13.1104 15.6303 10.6091C16.3354 9.3562 16.9719 8.19455 17.4321 7.34617C17.6622 6.92195 17.8483 6.576 17.9769 6.33607C18.0412 6.2161 18.0911 6.12263 18.125 6.05912L18.1635 5.98677L18.1733 5.96827L18.1758 5.96357L18.1764 5.96238L18.1766 5.96208C18.1766 5.96201 18.1767 5.96197 18 5.86823ZM18 5.86823L17.8365 5.75308C17.8973 5.66675 18.0147 5.64254 18.1047 5.69779C18.1946 5.75304 18.2262 5.86871 18.1767 5.96197L18 5.86823Z"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

function LandingPage() {
  const targetRef = useRef(null);

  const handleScrollClick = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const problemsList = [
    {
      isQuestion: true,
      title:
        "Your current development costs are unsustainable for long-term growth?",
    },
    {
      isQuestion: true,
      title:
        "Your team lacks the advanced technical skills needed to implement the latest technologies effectively?",
    },
    {
      isQuestion: true,
      title: "Software releases take too much time?",
    },
    {
      isQuestion: true,
      title: "Bugs and low code quality cause delays and frustration?",
    },
  ];

  const problemsList2 = [
    {
      isQuestion: false,
      isError: true,
      title: "High software development costs burn your marketing budget…",
    },
    {
      isQuestion: false,
      isError: true,
      title: "Burning marketing budget causes slow growth…",
    },
    {
      isQuestion: false,
      isError: true,
      title: "Slow growth is frustrating for SaaS founders and investors...",
    },
    {
      isQuestion: false,
      isCheck: true,
      title: "You can end this today.",
    },
  ];

  const solutionsList = [
    {
      isQuestion: false,
      title:
        "Isn't it time to reduce software development costs, release faster while keeping a high quality standard?",
    },
    {
      isQuestion: false,
      title:
        "We provide you with a software development team (or single experts) from a talent pool of Indias Top 3% tech talent. Our team’s quality is proven by ISO 9001 and ISO 27001 certification.",
    },
    {
      isQuestion: false,
      title: "We ensure to integrate seamlessly with your current team.",
    },
    {
      isQuestion: false,
      title:
        "Our dedcated project manager will ensure seamless communication, keeping your projects on track and meeting tight deadlines.",
    },
  ];

  const IconCardListData = [
    {
      icon: ReduceCostImg,
      altTxt: "Reduce costs with global talent.",
      heading: "Reduce costs with global talent.",
      subTitle:
        "If you have to spend less money for software development, you can spend more for marketing and activities to grow much faster.",
    },
    {
      icon: TestingImg,
      altTxt: "Rigorous testing for high-quality code",
      heading: "Rigorous testing for high-quality code",
      subTitle:
        "With our testing process and the ISO 9001 and ISO 27001 certification of our team, you get reliable quality with each deployment.",
    },
    {
      icon: CommunicationIcon,
      altTxt: "Dedicated project manager for reliable communication.",
      heading: "Dedicated project manager for reliable communication.",
      subTitle:
        "We free up your time and your budget so you can focus on growth. Our project manager ensures all requirements are delivered on time.",
    },
    {
      icon: ProgressImg,
      altTxt: "Full transparency over cost and progress",
      heading: "Full transparency over cost and progress",
      subTitle:
        "With our ISO certified processes we provide your with full transparency over costs, results and progress every week. ",
    },
    {
      icon: DeadlineImg,
      altTxt: "Meet tight deadlines consistently.",
      heading: "Meet tight deadlines consistently.",
      subTitle:
        "With our team you can scale your software development capacity reliably and meet tight deadlines even with a high amount of new requirements in your backlog.",
    },
    {
      icon: DataIcon,
      altTxt: "100% data security compliance.",
      heading: "100% data security compliance.",
      subTitle:
        "Our processes, tools and team culture ensure your data is 100% secure and compliant.",
    },
  ];

  return (
    <Layout
      seo={{
        title: "Landing Page | Digiryte",
        ogtitle: "Landing Page | Digiryte",
        description:
          "Explore Digiryte's Clutch profile to see our track record of accelerating digital capabilities for startups, scaleups, and enterprises across various industries.",
        keywords:
          "Clutch, Digiryte reviews, digital capabilities, startups, scaleups, enterprises",
      }}
      isLandingPage={true}
      showHeader={false}
      pageUrl={"-offer"}
    >
      <GatsbySeo noindex={true} />
      <div className="body-container" style={{ padding: "20px 0" }}>
        <img className="" src={DigiryteLogo} alt="Digiryte" />
      </div>
      <div className="lpHeroSection hideAfter common-bg-light">
        <div className="heroSectionWrap body-container">
          <div className="leftSectionWrapper">
            <RevealAnimation component={"h2"} className="lp-bannerTitle">
              See How You Can Get An Offshore Team That Delivers Software 100%
              Matching Your Requirements or you don't pay.
            </RevealAnimation>

            <RevealAnimation animation="fade" className="list">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                  paddingTop: "15px",
                  fontSize: "18px",
                  letterSpacing: "0.03px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CheckIcon />
                  <p>
                    ONLY for SaaS businesses ready to scale up (min. 200K ARR
                    required)
                  </p>
                </div>
              </div>
            </RevealAnimation>
            <RevealAnimation component={"div"}>
              <Button
                btnWrapClassName="w-full"
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={RightArrowWhite}
                    alt="Lets Talk"
                  />
                }
                customClassName="info-view-resize-btn max-w-340"
                color="primary"
                variant="contained"
                label={"Book your free discovery call"}
                tooltip="no"
                onClick={() => {
                  window.fbq("track", "offerlead");
                  handleScrollClick();
                }}
              />
            </RevealAnimation>
          </div>
          <div className="rightSectionWrapper">
            <RevealAnimation
              component={"div"}
              animation={"slide-left"}
              className={`imageContainer`}
            >
              <img
                className={`hero-image rightSectionWrapper`}
                src={heroSection}
                alt={""}
              />
            </RevealAnimation>
          </div>
        </div>
      </div>
      {/* <div>
        <TrustedPartner
          showVideo={false}
          title={
            <>
              Trusted partner for startups and <br />
              Fortune 500 companies
            </>
          }
          showAllBtn={false}
          wrapClassName="bg-white"
          imgContainerClassName="custom-gap"
        />
      </div> */}

      <div className="mt-50">
        <div className="body-container">
          <RevealAnimation
            component={"div"}
            animation={"slide-left"}
            className="title-header title-underline"
          >
            Trusted partner for startups and <br />
            Fortune 500 companies
          </RevealAnimation>
        </div>
        <div className="mt-50">
          <AutoSlideImage
            imageUrl={ClientLogos}
            direction="left-to-right"
            customClass="slide-duration-40 mix-darken"
          />
        </div>
      </div>

      <div className="mt-50 common-bg-light">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            Are you experiencing these problem?
          </RevealAnimation>
          <LandingList objectList1={problemsList} objectList2={problemsList2} />
        </div>
      </div>
      <div className="mt-70 ">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            Here's better way to develop your SAAS product
          </RevealAnimation>
          <LandingList objectList1={solutionsList} objectList2={[]} />
        </div>
      </div>
      <div className="mt-50 common-bg-light">
        <LetsTalkCTA
          title="Ready to Scale your SaaS Business and Lower your Software
            Development Costs?"
          titleClass="text-center"
          wrapClass="no-border"
          btnText="Book your free discovery call"
          onClick={() => {
            window.fbq("track", "offerlead");
            handleScrollClick();
          }}
        />
      </div>
      <div className="mt-50 ">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            Here is how it works
          </RevealAnimation>
          <h6 className="feature-subtitle">
            We make software development a profitable investment for your
            business and a reliable offshoring experience you can count on.
            Here’s why we can deliver this:
          </h6>

          <div className="mt-50 mb-20">
            <IconCardList
              textClass="text-20"
              IconCardListData={IconCardListData}
            />
          </div>
        </div>
      </div>
      <div className="mt-70 common-bg-light">
        <LetsTalkCTA
          title="Unlock Faster Scaling for Your SaaS Business with Optimized Development Solutions!"
          titleClass="text-center"
          wrapClass="no-border"
          btnText="Book your free discovery call"
          onClick={() => {
            window.fbq("track", "offerlead");
            handleScrollClick();
          }}
        />
      </div>
      <div className="mt-50">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            <span className="red-line3letter">pro</span>cess
          </RevealAnimation>
          <h6 className="feature-subtitle">
            See the exact steps to get your software developed fast, reliably
            and at lower costs.
          </h6>
        </div>

        <div className="mt-20 ">
          <TitleImgSection
            title=""
            subTitle="Initial Consultation:"
            listItems={[
              {
                description:
                  "This step is crucial to align on technical and strategic objectives, ensuring that the project scope and desired outcomes are clear from the start.",
              },
              {
                description:
                  "It sets the foundation for a successful partnership and for our guarantee to deliver 100% of your requirements accurately.",
              },
            ]}
            btnLink=""
            imageUrl={InitialImg}
            wrapClassName="mt-50"
            stepCount=""
          />
        </div>
        <div className="mt-50 common-bg-light">
          <TitleImgSection
            title=""
            subTitle="Talent Sourcing:"
            listItems={[
              {
                description:
                  "We ensure that only the top 3% of tech talent is recruited, meeting your technical standards and fitting your company culture.",
              },
              {
                description:
                  "This step optimizes your budget and enhances the quality of software development.",
              },
            ]}
            btnLink=""
            imageUrl={TalentImg}
            wrapClassName="row-reverse"
            stepCount=""
          />
        </div>
        <div className="mt-20 ">
          <TitleImgSection
            title=""
            subTitle="Team Formation:"
            listItems={[
              {
                description:
                  "Custom-building of your agile team with developers, project managers, and QA specialists tailored to your requirements.",
              },
              {
                description:
                  "We ensure that the team is perfectly aligned with your project needs.",
              },
            ]}
            btnLink=""
            imageUrl={TeamImg}
            wrapClassName="mt-50"
            stepCount=""
          />
        </div>
        <div className="mt-50 common-bg-light">
          <TitleImgSection
            title=""
            subTitle="Integration and Onboarding:"
            listItems={[
              {
                description:
                  "We seamlessly integrate the team into your operations with the right tools and regular coordination meetings to ensure effective communication, alignment and high quality.",
              },
            ]}
            btnLink=""
            imageUrl={OnboardingImg}
            wrapClassName="row-reverse"
            stepCount=""
          />
        </div>
        <div className="mt-20 ">
          <TitleImgSection
            title=""
            subTitle="Agile Implementation:"
            listItems={[
              {
                description:
                  "We implement agile workflows to ensure your team is delivering fast results providing your with the quality and the flexibility you need. ",
              },
              {
                description:
                  "With our process you’ll see increasing development speed and quick adaptation to changes.",
              },
            ]}
            btnLink=""
            imageUrl={AgileImg}
            wrapClassName="mt-50 "
            stepCount=""
          />
        </div>
        <div className="mt-50 common-bg-light">
          <TitleImgSection
            title=""
            subTitle="Quality Assurance and Testing:"
            listItems={[
              {
                description:
                  "We use automated tools and dedicated software testers to ensure that all functional and quality standards are met, maintaining high-quality code and robust security protocols.",
              },
            ]}
            btnLink=""
            imageUrl={QualityImg}
            wrapClassName="row-reverse"
            stepCount=""
          />
        </div>
        <div className="mt-20 ">
          <TitleImgSection
            title=""
            subTitle="Performance Review and Scaling:"
            listItems={[
              {
                description:
                  "We regularly evaluate the team's impact on quality, speed of delivery and data security to ensure fast strategic adjustments.",
              },
              {
                description:
                  "Whenever you see a growing backlog, we can scale your team seamlessly.",
              },
            ]}
            btnLink=""
            imageUrl={PerformanceImg}
            wrapClassName="mt-50"
            stepCount=""
          />
        </div>
      </div>
      <div className="mt-70 common-bg-light">
        <LetsTalkCTA
          title="Maximize Efficiency and Minimize Costs – Ready to Elevate Your SaaS Strategy?"
          titleClass="text-center"
          wrapClass="no-border"
          btnText="Book your free discovery call"
          onClick={() => {
            window.fbq("track", "offerlead");
            handleScrollClick();
          }}
        />
      </div>

      <div className="mt-70 ">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            <span className="red-line3letter">Team</span> work for you
          </RevealAnimation>
          <div className="team-section">
            <div className="team-img">
              <img alt="ramesh" src={RameshImg} />
            </div>
            <div className="team-img">
              <img alt="ramesh" src={HarishImg} />
            </div>
            <div className="team-img">
              <img alt="ramesh" src={HarryImg} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-70 common-bg-light">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            <span className="red-line3letter">Abo</span>ut Digiryte
          </RevealAnimation>
          <p className="">
            At Digiryte, we understand the unique challenges faced by SaaS
            startups in today's fast-paced digital landscape. Since 2015, we
            have been a trusted partner for businesses looking to scale their
            software solutions efficiently and effectively. With a track record
            of 119 successful projects, our commitment to quality is underscored
            by our ISO 9001 and ISO 27001 certifications. Our team of experts is
            dedicated to delivering software that not only meets but exceeds
            your expectations, ensuring your business can adapt and thrive in an
            ever-evolving market.
          </p>
        </div>
      </div>
      <div ref={targetRef} className="">
        <InlineWidget url="https://calendly.com/harry-digiryte/discoverycall" />
      </div>
      <div className="mt-50"></div>
      <LPFooter
        ctaText="Book your free discovery call"
        ctaClick={() => {
          handleScrollClick();
          window.fbq("track", "offerlead");
        }}
        containerClass="customCta"
      />

      <div id="modal-booking"></div>
    </Layout>
  );
}

export default LandingPage;
