import React from "react";
import "./style.scss";

const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.26825" width="24" height="24" rx="12" fill="green" />
      <path
        d="M18 5.86823L17.8364 5.75312L17.8363 5.75327L17.8359 5.75391L17.834 5.75655L17.8265 5.76719L17.7965 5.80952C17.77 5.84695 17.7303 5.9026 17.6783 5.97528C17.5743 6.12065 17.4205 6.33415 17.2221 6.60627C16.8253 7.15052 16.2497 7.92922 15.5354 8.86637C14.1064 10.7409 12.1229 13.2483 9.90367 15.7811C9.37776 16.381 8.90051 16.6 8.49311 16.6261C8.08233 16.6524 7.70105 16.4853 7.36422 16.2294C7.02742 15.9736 6.75049 15.6402 6.55592 15.3658C6.45924 15.2295 6.38428 15.1097 6.33373 15.0244C6.30847 14.9818 6.28938 14.948 6.27678 14.9251L6.26281 14.8994L6.25956 14.8932L6.25889 14.8919L6.25886 14.8919C6.25884 14.8918 6.25882 14.8918 6.25881 14.8918L6.2588 14.8918C6.2192 14.8152 6.13499 14.7726 6.04984 14.7861C5.96465 14.7996 5.89771 14.8662 5.88377 14.9513C5.69619 16.0971 5.8337 16.9783 6.19672 17.6173C6.56149 18.2594 7.14102 18.634 7.78759 18.7867C9.06416 19.0883 10.625 18.5323 11.4303 17.46L11.4304 17.46C12.5341 15.9903 14.2229 13.1104 15.6303 10.6091C16.3354 9.3562 16.9719 8.19455 17.4321 7.34617C17.6622 6.92195 17.8483 6.576 17.9769 6.33607C18.0412 6.2161 18.0911 6.12263 18.125 6.05912L18.1635 5.98677L18.1733 5.96827L18.1758 5.96357L18.1764 5.96238L18.1766 5.96208C18.1766 5.96201 18.1767 5.96197 18 5.86823ZM18 5.86823L17.8365 5.75308C17.8973 5.66675 18.0147 5.64254 18.1047 5.69779C18.1946 5.75304 18.2262 5.86871 18.1767 5.96197L18 5.86823Z"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const QuestionIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.419922" width="24" height="24" rx="12" fill="#E89F38" />
      <path
        d="M10.9106 17.5099H13.1306V19.7299H10.9106V17.5099ZM7.6506 9.90986C7.63727 9.22986 7.7306 8.59653 7.9306 8.00986C8.14394 7.4232 8.44394 6.91653 8.8306 6.48986C9.21727 6.04986 9.6906 5.70986 10.2506 5.46986C10.8106 5.22986 11.4373 5.10986 12.1306 5.10986C12.7573 5.10986 13.3239 5.2032 13.8306 5.38986C14.3506 5.5632 14.7973 5.8232 15.1706 6.16986C15.5439 6.5032 15.8306 6.91653 16.0306 7.40986C16.2439 7.9032 16.3506 8.46986 16.3506 9.10986C16.3506 9.5232 16.2973 9.89653 16.1906 10.2299C16.0973 10.5499 15.9706 10.8432 15.8106 11.1099C15.6506 11.3765 15.4639 11.6232 15.2506 11.8499C15.0506 12.0765 14.8439 12.2965 14.6306 12.5099C14.4173 12.7099 14.2039 12.9165 13.9906 13.1299C13.7906 13.3299 13.6039 13.5499 13.4306 13.7899C13.2706 14.0165 13.1373 14.2699 13.0306 14.5499C12.9373 14.8165 12.8906 15.1232 12.8906 15.4699V16.2499H11.1906V15.3099C11.2173 14.7499 11.3173 14.2765 11.4906 13.8899C11.6773 13.4899 11.8973 13.1365 12.1506 12.8299C12.4173 12.5232 12.6906 12.2432 12.9706 11.9899C13.2639 11.7232 13.5306 11.4499 13.7706 11.1699C14.0239 10.8765 14.2239 10.5565 14.3706 10.2099C14.5173 9.8632 14.5773 9.4432 14.5506 8.94986C14.4973 8.21653 14.2573 7.6432 13.8306 7.22986C13.4173 6.81653 12.8506 6.60986 12.1306 6.60986C11.6506 6.60986 11.2373 6.69653 10.8906 6.86986C10.5439 7.0432 10.2506 7.27653 10.0106 7.56986C9.78394 7.8632 9.61727 8.20986 9.5106 8.60986C9.40394 9.00986 9.3506 9.4432 9.3506 9.90986H7.6506Z"
        fill="white"
      />
    </svg>
  );
};
const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 32 32"
    >
      <path
        fill="red"
        d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
      ></path>
    </svg>
  );
};

function LandingList({ objectList1, objectList2 }) {
  return (
    <div className="list-container">
      <div className="list-child">
        {objectList1?.map((item, index) => (
          <div key={index} className="list-card">
            {item.isQuestion ? <QuestionIcon /> : <CheckIcon />}
            <p>{item.title}</p>
          </div>
        ))}
      </div>
      <div className="list-child mt-30">
        {objectList2?.map((item, index, arr) => (
          <div key={index} className="list-card">
            {item.isQuestion ? (
              <QuestionIcon />
            ) : item.isError ? (
              <ErrorIcon />
            ) : (
              <CheckIcon />
            )}
            <p className="">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LandingList;
